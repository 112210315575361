<template>
  <div class="servicebar-container">
    <div class="box-wrapper">
      <div class="row row-eq-height m-0">
        <router-link
          v-for="item in data"
          :key="item.text"
          :to="{ name: 'ServiceSub', params: { subname: item.route } }"
          class="col-6 col-sm-4 col-md item"
        >
          <div class="image">
            <app-image
              :lazy-srcset="
                `${item.image[1]} 200w,` +
                  `${item.image[2]} 934w,` +
                  `${item.image[3]} 1400w,`
              "
              :src="item.image[0]"
              :lazy-src="item.image[3]"
              :alt="`revealing-clinic-${item.text}-service`"
            />
          </div>
          <div class="text">
            <div class="">
              {{ item.text }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from "../appimage/AppImage";
export default {
  name: "ServiceBar",
  components: {
    AppImage
  },
  data() {
    return {
      data: [
        {
          text: "Cosmetic Surgery",
          image: [
            require("@images/service/surgery_z6dmxf_c_scale,w_200-blur.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_200.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_934.jpg"),
            require("@images/service/surgery_z6dmxf_c_scale,w_1400.jpg")
          ],
          route: "surgery"
        },
        {
          text: "Wrinkle",
          image: [
            require("@images/service/wrinkle_l32wsk_c_scale,w_200-blur.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_200.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_928.jpg"),
            require("@images/service/wrinkle_l32wsk_c_scale,w_1400.jpg")
          ],
          route: "wrinkle"
        },
        {
          text: "Fat and Lifting",
          image: [
            require("@images/service/fat-lifting_hwmf10_c_scale,w_200-blur.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_200.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_952.jpg"),
            require("@images/service/fat-lifting_hwmf10_c_scale,w_1400.jpg")
          ],
          route: "fat"
        },
        {
          text: "Skin",
          image: [
            require("@images/service/skin_v5bo4q_c_scale,w_200-blur.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_200.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_928.jpg"),
            require("@images/service/skin_v5bo4q_c_scale,w_1400.jpg")
          ],
          route: "skin"
        },
        {
          text: "Acne Scar",
          image: [
            require("@images/service/acne_ypgnbg_c_scale,w_200-blur.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_200.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_952.jpg"),
            require("@images/service/acne_ypgnbg_c_scale,w_1400.jpg")
          ],
          route: "scar"
        },
        {
          text: "HIFU",
          image: [
            require("@images/service/hifu_c4ambw_c_scale,w_200-blur.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_200.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_925.jpg"),
            require("@images/service/hifu_c4ambw_c_scale,w_1400.jpg")
          ],
          route: "tech"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/service/service_bar";
</style>
